import { initializeApp } from 'firebase/app';
import {getFirestore} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAg_XDhtAZnIvOSkCr-lZgOsvsW9XlVULQ",
    authDomain: "carboncodes-d175d.firebaseapp.com",
    databaseURL: "https://carboncodes-d175d-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "carboncodes-d175d",
    storageBucket: "carboncodes-d175d.appspot.com",
    messagingSenderId: "1092586371674",
    appId: "1:1092586371674:web:4e9c6a6d5da100c84e7663",
    measurementId: "G-NDC8GFG30L"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export default app;
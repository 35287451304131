import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The team at CarbonCodes did an excellent job designing and developing our website. They were professional, responsive, and easy to work with. The end result exceeded our expectations and has helped us attract more customers and generate more sales.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Symon </span> - COO, AMERIMAR ENTERPRISES INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The app development team at CarbonCodes company was a pleasure to work with. They took the time to understand our business and tailor the app to our specific needs. The end result was a beautifully designed, user-friendly app that has helped us to increase our customer engagement and sales. We highly recommend CarbonCodes company for anyone in need of app development services.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Rayhan </span> - COO, HypoGets INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>As a small business, we were on a tight budget for our website development project. CarbonCodes was able to work within our constraints and deliver a website that exceeded our expectations. The finished product is professional, easy to navigate, and has helped us establish a strong online presence.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Tuhin </span> - CTO, Tenzo Ltd.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The team at CarbonCodes company was an absolute pleasure to work with. They were extremely knowledgeable and provided valuable insights throughout the development process. They also went above and beyond to ensure that the app was user-friendly, visually appealing, and met all of our requirements. We highly recommend CarbonCodes company to anyone looking for a high-quality app development service.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Jannat Tumpa </span> - CEO, Cybershot.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We had a great experience working with CarbonCodes on our website development project. They took the time to understand our needs and provided valuable input throughout the process. The end result is a website that is not only visually stunning but also highly effective in driving conversions.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Tania </span> - COO, Soft Express, INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The digital marketing team at CarbonCodes agency has been a game changer for our business. They took the time to understand our target audience and developed a comprehensive digital marketing strategy that has helped us to increase our online visibility, website traffic, and customer engagement. The team is always available to answer our questions and provide valuable insights, and their results speak for themselves. We highly recommend XYZ agency to any business looking to take their digital marketing to the next level.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Omar </span> - CEO, TotalMax, INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The team at CarbonCodes agency is truly a cut above the rest when it comes to graphic design. They were able to take our vision and turn it into a visually stunning and effective design. From logos to brochures, the team delivered high-quality work that exceeded our expectations. They were easy to work with, responsive and made sure that we were satisfied with the final product. We highly recommend CarbonCodes agency for any business looking for top-notch graphic design services.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Fatima Ma </span> - CTO, Kotlt, INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We were extremely impressed with the level of service and expertise provided by the software testing team at CarbonCodes agency. They were thorough in their testing procedures and identified issues that we never would have found on our own. Their detailed reports and recommendations were invaluable in helping us to improve the quality and functionality of our software. The team was also very responsive and easy to work with. We highly recommend CarbonCodes agency to any business in need of top-notch software testing services.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Jinta </span> - COO, Softonic, INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
import React from 'react';
import {FaWhatsapp ,FaGithub ,FaFacebookF , FaLinkedinIn, FaBuilding, FaPhoneAlt, FaEnvelope} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://web.facebook.com/profile.php?id=100088506534789'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/89915026'},
    {Social: <FaGithub /> , link: 'https://github.com/Carbon-Codes'},
    {Social: <FaWhatsapp /> , link: 'https://wa.me/+8801893483738'},
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <a href="/home-one">
                                    <img src="/assets/images/logo/logo-dark.png" alt="Logo images"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner">
                            <div className="text footer_contact text-lg-center text-center">
                                <p><a href="https://goo.gl/maps/fAh6ccet6W13EcfX8" target="_blank">Natore, Bangladesh</a><span><FaBuilding/></span></p>
                                <p><a href="tel:+880 1893 483738">+880 1893 483738</a><span><FaPhoneAlt/></span></p>
                                <p><a href="mailto:carboncodes.net@gmail.com">carboncodes.net@gmail.com</a><span><FaEnvelope/></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='wrapper'>
                <div className="inner">
                    <div className="text footer_copyright text-lg-center text-center">
                        <p>Copyright © 2023 CarbonCodes. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;
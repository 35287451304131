import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';

import { db } from '../../Firebase';
import { setDoc, collection, doc } from 'firebase/firestore';
const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. We will contact you soon.</p>
    )
}
function ContactForm({ props }) {
    const [result, showresult] = useState(false);


    setTimeout(() => {
        showresult(false);
    }, 5000);

    const userForm = (e) => {

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const currenttimestap = new Date().getTime();

        e.preventDefault();
        const fullName = e.target.fullname;
        const email = e.target.email;
        const phone = e.target.phone;
        const subject = e.target.subject;
        const message = e.target.message;
        const data = {
            fullName: fullName.value,
            email: email.value,
            phone: phone.value,
            subject: subject.value,
            message: message.value,
            usertimezone: timeZone,
            currenttimestap: currenttimestap,
            status: "requested"
        }
        
        const newContactDoc = doc(collection(db, "contacts"));
        setDoc(newContactDoc, data)
        .then(() => {
            showresult(true);
            e.target.reset();
        });
    }
    return (
        <form action="" onSubmit={userForm}>
            <div className="rn-form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                />
            </div>

            <div className="rn-form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Contact Now</button>
            </div>

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;

import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiGlobe, FiSmartphone, FiShoppingCart, FiSettings, FiFigma } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiGlobe />,
        title: 'Web Development',
        description: 'Develop robust online applications to suit your business needs and cater to your clients faithfully.'
    },
    {
        icon: <FiSmartphone />,
        title: 'Mobile App Development',
        description: 'Developing innovative and native mobile apps for Android, iOS, BlackBerry and Windows platforms.'
    },
    {
        icon: <FiShoppingCart />,
        title: 'E-Commerce',
        description: 'We provide ultimate e-commerce solution as well as customizations for your e-commerce business.'
    },
    { 
        icon: <FiFigma />,
        title: 'UX/UI',
        description: 'Design an engaging product that is easy-to-use, attractive and functional.'
    },
    {
        icon: <FiUsers />,
        title: 'SEO & Marketing',
        description: 'We have a growing and diverse client base that we help regularly to rank better on search engines.'
    },
    { 
        icon: <FiSettings />,
        title: 'Software Testing',
        description: 'Using the latest tools & concrete methodologies to ensure the readiness of your digital solution.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a>
                            {/* <a href="/service-details"> */}
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
